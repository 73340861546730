import { Accordion } from '@/components/accordion';
// import { Dropdown } from '@/components/Dropdown';
// import { fontsMaps } from '@/components/fontsMaps';
import Layout from '@/components/Layout';
import * as React from 'react';
import Copy from '../assets/images/copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export default function FontEdit({ location, pageContext }) {
  const [text, setText] = React.useState(
    location?.state?.text || `Пример текста`,
  );
  // const [decorator, setDecorator] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>();

  // const setDecorator$ = (context: string) => {
  //   setDecorator(context);
  // };

  const emojis = React.useMemo(() => {
    return pageContext.emoji.pageProps.emojiCategories;
  }, []);

  return (
    <Layout
      header={'Создайте свой шрифт'}
      descr={'Отредактируйте текст, добавив крутые символы'}
      showFooter={false}
    >
      <Helmet>
        {/* <meta charSet="utf-8" /> */}
        <title>{`Изменение шрифта	онлайн. | PrettyFont.net`}</title>
        <link rel="canonical" href={location.href} />
        <meta
          property="og:title"
          content={`Изменение шрифта онлайн. | PrettyFont.net`}
        />
        <meta
          name="description"
          content={`🤩 Измени свой шрифт онлайн. Добавь множество крутых символов и эмодзи`}
        />
        <meta
          property="og:description"
          content={`🤩 Измени свой шрифт онлайн. Добавь множество крутых символов и эмодзи`}
        />
        <meta property="og:url" content={location.href} />
        {/* <meta property="og:image:width" content="279" /> */}
        {/* <meta property="og:image:height" content="279" /> */}
        {/* <meta
          property="og:image"
          content="https://textgenerator.ru/assets/favicons/og-image.jpg"
        /> */}
      </Helmet>
      <div className="flex justify-center sticky top-3 flex-col m-auto	 mb-60px max-w-max-input z-10">
        <div className="flex-auto p-big bg-gradient-to-r from-my-blue to-my-violet rounded-12px shadow-my">
          <div className="bg-white h-full rounded-8px flex divide-x">
            {/* <Dropdown right={true} setDecorator={setDecorator$} /> */}
            <div className="relative w-full ">
              <input
                ref={inputRef}
                className="filled input appearance-none w-full px-20px pt-36px pb-16px focus focus:outline-none active:outline-none text-grey-darker text-text  rounded-8px"
                id="text"
                type="text"
                autoFocus
                value={text}
                onChange={(evt) => {
                  setText(evt.target.value);
                }}
              />
              <label
                htmlFor="text"
                className="label absolute mb-0 leading-tighter text-gray-400 cursor-text text-dec"
              >
                Введите текст
              </label>
            </div>
            {/* <Dropdown right={false} setDecorator={setDecorator$} /> */}
          </div>
        </div>
        <div className="flex justify-center">
          <CopyToClipboard
            text={text}
            onCopy={() =>
              toast(`${text} скопирован`, {
                // eslint-disable-next-line @typescript-eslint/quotes
                closeButton: false,
              })
            }
          >
            <div className="bg-white pl-36px pr-36px pt-20px pb-20px max-w-366px rounded-b shadow-my space-x-3 group cursor-pointer flex flex-row">
              <Copy className="group-hover:filter-violet" />
              <span className="text-base text-my-blue group-hover:text-my-violet">
                Копировать
              </span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
      <div className="space-y-20px">
        {emojis.map((category) => (
          <Accordion
            label={category.name}
            count={category.emojis.length}
            id={category.slug}
          >
            <div className="flex flex-wrap -mx-px overflow-hidden">
              {category.emojis.map((el: { emoji: any }) => (
                <div
                  className="sectionSymbol font-emoji hover:shadow-my hover:text-blue-500"
                  dangerouslySetInnerHTML={{ __html: el.emoji }}
                  onClick={(evt) => {
                    const position = inputRef.current.selectionEnd;
                    const newValue = [
                      inputRef.current.value.slice(0, position),
                      evt.target.innerText,
                      inputRef.current.value.slice(position),
                    ].join('');
                    setText(newValue);
                    inputRef.current.focus();
                    inputRef.current.selectionStart = position + 1;
                  }}
                />
              ))}
            </div>
          </Accordion>
        ))}
      </div>
    </Layout>
  );
}
function tr(text: string): any {
  throw new Error('Function not implemented.');
}
