import * as React from 'react';

export interface IAccordionProps extends React.FC {
  label: string;
  count: number;
  id: string;
  children: React.ReactNode;
}

export const Accordion: React.FunctionComponent<IAccordionProps> = ({
  children,
  label,
  count,
  id,
}) => {
  return (
    <div className="tab w-full overflow-hidden rounded">
      <input
        className="absolute opacity-0"
        id={id}
        type="checkbox"
        name={label}
      />
      <label
        className="block pt-20px pb-20px pl-30px pr-30px text-text cursor-pointer bg-white"
        htmlFor={id}
      >
        {label} <span className="text-my-dividers text-base">{count}</span>
      </label>
      <div className="tab-content overflow-hidden bg-white leading-normal">
        <p className="p-30px">{children}</p>
      </div>
    </div>
  );
};
